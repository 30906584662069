@use "@porsche-design-system/components-react/styles" as *;

.popover-icon {
  z-index: 10;
}

.popover-flex {
  display: flex;
  flex-direction: column;
  gap: $pds-spacing-static-medium
}
