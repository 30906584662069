@use "@porsche-design-system/components-react/styles" as *;

.unauthorized {
  margin-top: $pds-spacing-static-large;
  margin-bottom: $pds-spacing-static-large;

  .heading {
    margin-bottom: $pds-spacing-static-medium;
  }

  .banner {
    margin-bottom: $pds-spacing-static-medium;
    width: 100%;
  }
}
