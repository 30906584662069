@use "@porsche-design-system/components-react/styles" as *;

.footer {
  background: $pds-theme-dark-background-base;

  .footer-wrapper {
    max-width: 96rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
    width: calc(100vw - (max(1rem, 7vw) * 2));

    @media(max-width: 759px) {
      padding-top: 2rem;
    }

    .footer-groups {
      display: flex;
      justify-content: space-between;

      @include pds-media-query-min-max('base', 's') {
        margin-bottom: 2rem;
        flex-direction: column;
      }

      .footer-badge {
        margin-right: 1.6rem;
      }
    }

    .footer-divider {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  }
}
