@use "@porsche-design-system/components-react/styles" as *;

.suspension-toggle {
  display: flex;
  align-items: center;
}

.mno-logo {
  max-width: 100%;
  max-height: 100%;
}

.mno-logo-container {
  display: flex;
  flex-direction: column;

  @include pds-media-query-min-max('base', 's') {
    margin-top: $pds-spacing-static-medium;
  }

  @include pds-media-query-min('s') {
    align-items: flex-end;
  }

  .mno-logo-wrapper {
    @include pds-media-query-min-max('base', 's') {
      width: 33%;
    }
    @include pds-media-query-min('s') {
      width: 38%;
    }
  }
}

.mno-text {
  margin-bottom: $pds-spacing-static-small;
}

.top-bar-description {
  @include pds-media-query-min-max('base', 's') {
    display: none;
  }
}

.mno-info {
  @include pds-media-query-min('s') {
    align-self: end;
  }
}

.content {
  @include pds-media-query-min-max('base', 'm') {
    margin-bottom: $pds-spacing-static-large;
  }
}
