@use "@porsche-design-system/components-react/styles" as *;

.navigation-wrapper {
  height: 100vh;

  .navigation {
    margin-top: $pds-spacing-static-large;
    margin-bottom: $pds-spacing-static-xx-large;

    @include pds-media-query-min-max('base', 'm') {
      margin-bottom: $pds-spacing-static-medium;
    }
  }

}
