/**
 * Theme light as default theme
 * This is temporary file, should be removed after migrating to new porsche-design-system color schema
 */

$p-color-brand: #d5001c;
$p-color-theme-light-brand: $p-color-brand;

$p-color-default: #000;
$p-color-theme-light-default: $p-color-default;

$p-color-background-default: #fff;
$p-color-theme-light-background: $p-color-background-default;
$p-color-background-surface: #f2f2f2;
$p-color-theme-light-surface: $p-color-background-surface;
$p-color-background-shading: rgba(#0e1418, 0.9);
$p-color-theme-light-background-shading: $p-color-background-shading;

$p-color-neutral-contrast-high: #323639;
$p-color-theme-light-neutral-contrast-high: $p-color-neutral-contrast-high;
$p-color-neutral-contrast-medium: #626669;
$p-color-theme-light-neutral-contrast-medium: $p-color-neutral-contrast-medium;
$p-color-neutral-contrast-low: #e3e4e5;
$p-color-theme-light-neutral-contrast-low: $p-color-neutral-contrast-low;

$p-color-notification-success: #018a16;
$p-color-theme-light-notification-success: $p-color-notification-success;
$p-color-notification-success-soft: #e5f3e7;
$p-color-theme-light-notification-success-soft: $p-color-notification-success-soft;
$p-color-notification-warning: #ff9b00;
$p-color-theme-light-notification-warning: $p-color-notification-warning;
$p-color-notification-warning-soft: #fff5e5;
$p-color-theme-light-notification-warning-soft: $p-color-notification-warning-soft;
$p-color-notification-error: #e00000;
$p-color-theme-light-notification-error: $p-color-notification-error;
$p-color-notification-error-soft: #fae6e6;
$p-color-theme-light-notification-error-soft: $p-color-notification-error-soft;
$p-color-notification-neutral: #0061bd;
$p-color-theme-light-notification-neutral: $p-color-notification-neutral;
$p-color-notification-neutral-soft: #e5eff8;
$p-color-theme-light-notification-neutral-soft: $p-color-notification-neutral-soft;

$p-color-state-hover: #d5001c;
$p-color-theme-light-state-hover: $p-color-state-hover;
$p-color-state-active: #d5001c;
$p-color-theme-light-state-active: $p-color-state-active;
$p-color-state-focus: currentColor;
$p-color-theme-light-state-focus: $p-color-state-focus;
$p-color-state-disabled: #96989a;
$p-color-theme-light-state-disabled: $p-color-state-disabled;

/**
 * Theme dark as optional
 */

$p-color-theme-dark-brand: #d5001c;

$p-color-theme-dark-default: #fff;

$p-color-theme-dark-background-default: #0e1418;
$p-color-theme-dark-background-surface: #262b2e;
$p-color-theme-dark-background-shading: rgba($p-color-theme-dark-background-default, 0.9);

$p-color-theme-dark-neutral-contrast-high: #e3e4e5;
$p-color-theme-dark-neutral-contrast-medium: #b0b1b2;
$p-color-theme-dark-neutral-contrast-low: #4a4e51;

$p-color-theme-dark-notification-success: #01ba1d;
$p-color-theme-dark-notification-success-soft: #bfeec6;
$p-color-theme-dark-notification-warning: #ff9b00;
$p-color-theme-dark-notification-warning-soft: #ffe6bf;
$p-color-theme-dark-notification-error: #fc1717;
$p-color-theme-dark-notification-error-soft: #fec5c5;
$p-color-theme-dark-notification-neutral: #2193ff;
$p-color-theme-dark-notification-neutral-soft: #c7e4ff;

$p-color-theme-dark-state-hover: #ff0223;
$p-color-theme-dark-state-active: #ff0223;
$p-color-theme-dark-state-focus: currentColor;
$p-color-theme-dark-state-disabled: #7c7f81;

/**
 * Theme light-electric as optional
 */

$p-color-theme-light-electric-brand: #00b0f4;

$p-color-theme-light-electric-default: $p-color-default;

$p-color-theme-light-electric-background-default: $p-color-background-default;
$p-color-theme-light-electric-background-surface: $p-color-background-surface;
$p-color-theme-light-electric-background-shading: $p-color-background-shading;

$p-color-theme-light-electric-neutral-contrast-high: $p-color-neutral-contrast-high;
$p-color-theme-light-electric-neutral-contrast-medium: $p-color-neutral-contrast-medium;
$p-color-theme-light-electric-neutral-contrast-low: $p-color-neutral-contrast-low;

$p-color-theme-light-electric-notification-success: $p-color-notification-success;
$p-color-theme-light-electric-notification-success-soft: $p-color-notification-success-soft;
$p-color-theme-light-electric-notification-warning: $p-color-notification-warning;
$p-color-theme-light-electric-notification-warning-soft: $p-color-notification-warning-soft;
$p-color-theme-light-electric-notification-error: $p-color-notification-error;
$p-color-theme-light-electric-notification-error-soft: $p-color-notification-error-soft;
$p-color-theme-light-electric-notification-neutral: $p-color-notification-neutral;
$p-color-theme-light-electric-notification-neutral-soft: $p-color-notification-neutral-soft;

$p-color-theme-light-electric-state-hover: #00b0f4;
$p-color-theme-light-electric-state-active: #00b0f4;
$p-color-theme-light-electric-state-focus: $p-color-state-focus;
$p-color-theme-light-electric-state-disabled: $p-color-state-disabled;

/**
 * Theme dark-electric as optional
 */

$p-color-theme-dark-electric-brand: #00b0f4;

$p-color-theme-dark-electric-default: $p-color-theme-dark-default;

$p-color-theme-dark-electric-background-default: $p-color-theme-dark-background-default;
$p-color-theme-dark-electric-background-surface: $p-color-theme-dark-background-surface;
$p-color-theme-dark-electric-background-shading: $p-color-theme-dark-background-shading;

$p-color-theme-dark-electric-neutral-contrast-high: $p-color-theme-dark-neutral-contrast-high;
$p-color-theme-dark-electric-neutral-contrast-medium: $p-color-theme-dark-neutral-contrast-medium;
$p-color-theme-dark-electric-neutral-contrast-low: $p-color-theme-dark-neutral-contrast-low;

$p-color-theme-dark-electric-notification-success: $p-color-theme-dark-notification-success;
$p-color-theme-dark-electric-notification-success-soft: $p-color-theme-dark-notification-success-soft;
$p-color-theme-dark-electric-notification-warning: $p-color-theme-dark-notification-warning;
$p-color-theme-dark-electric-notification-warning-soft: $p-color-theme-dark-notification-warning-soft;
$p-color-theme-dark-electric-notification-error: $p-color-theme-dark-notification-error;
$p-color-theme-dark-electric-notification-error-soft: $p-color-theme-dark-notification-error-soft;
$p-color-theme-dark-electric-notification-neutral: $p-color-theme-dark-notification-neutral;
$p-color-theme-dark-electric-notification-neutral-soft: $p-color-theme-dark-notification-neutral-soft;

$p-color-theme-dark-electric-state-hover: #00b0f4;
$p-color-theme-dark-electric-state-active: #00b0f4;
$p-color-theme-dark-electric-state-focus: $p-color-theme-dark-state-focus;
$p-color-theme-dark-electric-state-disabled: $p-color-theme-dark-state-disabled;

/**
 * External brand colors
 */
$p-color-external-facebook: #1877f2;
$p-color-external-google: #4285f4;
$p-color-external-instagram: #e1306c;
$p-color-external-kakaotalk: #fae300;
$p-color-external-linkedin: #0077b5;
$p-color-external-naver: #03cf5d;
$p-color-external-pinterest: #e60023;
$p-color-external-reddit: #ff4500;
$p-color-external-tiktok: #fe2c55;
$p-color-external-twitter: #1da1f2;
$p-color-external-wechat: #1aad19;
$p-color-external-whatsapp: #25d366;
$p-color-external-xing: #006567;
$p-color-external-youtube: #ff0000;
