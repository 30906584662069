@use "@porsche-design-system/components-react/styles" as *;

.footer-group-header {
  color: $pds-theme-dark-contrast-medium;
  margin-bottom: 1.5rem;
}

.badges {
  padding-left: 0 !important;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  align-content: space-between;
  gap: $pds-spacing-static-medium $pds-spacing-static-x-large;

  .badge {
    text-align: left;
    padding-left: 0 !important;

    img {
      background-color: $pds-theme-light-background-base;
      width: 72px;
    }
  }
}
