@use "@porsche-design-system/components-react/styles" as *;

.partner-portal-redirect {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;

  .banner {
    max-width: 70%;
    margin-top: $pds-spacing-static-medium;
  }
}
