@use "@porsche-design-system/components-react/styles" as *;
@use "utils/scss/_color" as *;

.legal {
  color: $p-color-neutral-contrast-low;

  a {
    color: $p-color-neutral-contrast-low;
    text-decoration: none;

    &:hover {
      color: $p-color-state-hover;
    }
  }
}
