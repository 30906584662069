.iitc-test-container {
  flex-shrink: 0;
  flex-basis: auto;
  margin: 0 auto;
  width: 100%;
  max-width: 120rem;
  padding: 0 max(1rem, 7vw);
}

.iitc-root {
  display: flex;
}

.iitc-grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: stretch;
  @media (min-width: 1000px) {
    min-height: 25rem;
    grid-auto-rows: 1fr;
  }
}

.iitc-grid-item {
  @media (min-width: 1000px) {
    grid-column: 1 / 6;
  }
  grid-column: 1 / 13;
}