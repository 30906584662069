@use "@porsche-design-system/components-react/styles" as *;

.footer-group-header {
  color: $pds-theme-dark-contrast-medium;
  margin-bottom: 1.5rem;
}

.mobile-footer-group-header {
  color: $pds-theme-dark-contrast-medium;
}

.footer-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ref {
  margin-bottom: 0.8rem;
}
